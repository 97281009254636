import React from "react";
import { Link } from "react-router-dom";
import {
  BsLinkedin,
  BsGithub,
  BsYoutube,
  BsInstagram,
  BsX,
  BsList,
} from "react-icons/bs";
import newsletter from "../images/newsletter.png";

const Footer = () => {
  const [showLinks, setShowLinks] = React.useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-md-5 mb-3 mb-md-0">
              <div className="footer-top-data d-flex gap-30 align-items-center">
                <img src={newsletter} alt="newsletter" />
                <h2 className="mb-0 text-white fs-5">Sign Up for Newsletter</h2>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control py-1"
                  placeholder="Your Email Address"
                  aria-label="Your Email Address"
                  aria-describedby="basic-addon2"
                />
                <span className="input-group-text p-2 d-block mt-2 mt-md-0" id="basic-addon2">
                  Subscribe
                </span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row g-3">
                <div className="col-12 col-md-4">
                  <h4 className="text-white mb-4">Contact Us</h4>
                  <div>
                    <address className="text-white fs-6">
                      Kampala, Uganda, Kyambogo, Plt1 <br />{" "}
                      Uganda,Kampala <br />
                      PinCode: 382007
                    </address>
                    <a
                      href="tel:+256 70123 123 6"
                      className="mt-3 d-block mb-1 text-white text-decoration-underline"
                    >
                      +256 70123 123 6
                    </a>
                    <a
                      href="mailto:info@amaja.co"
                      className="mt-2 d-block mb-0 text-white text-decoration-underline"
                    >
                      info@amaja.co
                    </a>
                    <div className="social_icons d-flex align-items-center gap-30 mt-4">
                      <a className="text-white text-decoration-underline" href="#">
                        <BsLinkedin className="fs-4" />
                      </a>
                      <a className="text-white text-decoration-underline" href="#">
                        <BsInstagram className="fs-4" />
                      </a>
                      <a className="text-white text-decoration-underline" href="#">
                        <BsGithub className="fs-4" />
                      </a>
                      <a className="text-white text-decoration-underline" href="#">
                        <BsYoutube className="fs-4" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <h4 className="text-white mb-4">Information</h4>
                  <div className="footer-link d-flex flex-column">
                    <Link to="/privacy-policy" className="text-white py-2 mb-1 text-decoration-underline">
                      Privacy Policy
                    </Link>
                    <Link to="/refund-policy" className="text-white py-2 mb-1 text-decoration-underline">
                      Refund Policy
                    </Link>
                    <Link to="/shipping-policy" className="text-white py-2 mb-1 text-decoration-underline">
                      Shipping Policy
                    </Link>
                    <Link to="/term-conditions" className="text-white py-2 mb-1 text-decoration-underline">
                      Terms & Conditions
                    </Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Blogs</Link>
                  </div>
                </div>
                <div className="col-12 col-md-2 d-none d-md-block">
                  <h4 className="text-white mb-4">Account</h4>
                  <div className="footer-link d-flex flex-column">
                    <Link className="text-white py-2 mb-1 text-decoration-underline">About Us</Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Faq</Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Contact</Link>
                  </div>
                </div>
                <div className="col-12 col-md-2 d-none d-md-block">
                  <h4 className="text-white mb-4">Quick Links</h4>
                  <div className="footer-link d-flex flex-column">
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Laptops</Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Headphones</Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Tablets</Link>
                    <Link className="text-white py-2 mb-1 text-decoration-underline">Watch</Link>
                  </div>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()}  AMAJA
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
