import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import ContainerSlider from "../components/ContainerSlider";
import { services } from "../utils/Data";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import wishlist from "../images/wishlist.svg";
import watch from "../images/watch.jpg";
import watch2 from "../images/watch-1.avif";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { getAllProducts } from "../features/products/productSlilce";
import ReactStars from "react-rating-stars-component";
import { addToWishlist } from "../features/products/productSlilce";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// couresel

import { Carousel, ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getblogs();
    getProducts();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };

  const getProducts = () => {
    dispatch(getAllProducts());
  };

  const addToWish = (id) => {
    //alert(id);
    dispatch(addToWishlist(id));
  };

  // Progress Indicator
  const [activeIndex, setActiveIndex] = useState(0);
  const totalItems = 3; // Number of payments per item
  const totalItems1 = 3; // Number of payments per item
  const totalItems2 = 3; // Number of payments per item
  const totalItems3 = 3; // Number of payments per item
  const totalItems4 = 3; // Number of payments per item

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const percentage1 = ((activeIndex + 1) / totalItems1) * 100;
  const percentage2 = ((activeIndex + 1) / totalItems2) * 100;
  const percentage3 = ((activeIndex + 1) / totalItems3) * 100;
  const percentage4 = ((activeIndex + 1) / totalItems4) * 100;
  return (
    <>
      <ContainerSlider class1="home-wrapper-1 py-5">
        <Carousel>
          <Carousel.Item>
            <div className="main-banner position-relative">
              <img
                src="images/amaja1.png"
                className="d-block w-100 img-fluid rounded-3"
                alt="main banner"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="main-banner position-relative">
              <img
                src="images/amaja2.png"
                className="d-block w-100 img-fluid rounded-3"
                alt="second banner"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="main-banner position-relative">
              <img
                src="images/amaja3.png"
                className="d-block w-100 img-fluid rounded-3"
                alt="second banner"
              />
            </div>
          </Carousel.Item>
          {/* Add more Carousel.Items as needed */}
        </Carousel>
      </ContainerSlider>

      {/* User Wallet Info And Current four in Cart */}

      <Container class1="home-wrapper-1 py-5">
        <div className="row">
          <div className="col-6 remeoved-sm-screen">
            <div className="main-banner position-relative ">
              <img
                src="images/wallet1.jpg"
                className="img-fluid rounded-3"
                alt="main banner"
              />
              <div className="main-banner-content position-absolute">
                <h4> Wallet</h4>
                <p>Simple And Secure </p>
                <Link to="/wallet" className="button">
                  GO TO WALLET
                </Link>
              </div>
            </div>
          </div>
          {/* wallet section for small screens  */}
          <div className="col-lg-12 rm-on-lg-screen">
            <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center">
              <div className="small-banner position-relative">
                <img
                  src="images/walletsm-33.png"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>
                    Click to <br />
                    Go To <br /> Your Wallet.
                  </h5>

                  <Link to="/wallet" className="button">
                    GO TO WALLET
                  </Link>
                </div>
              </div>
              <div className="small-banner position-relative">
                <img
                  src="images/catbanner-01.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now.</h5>
                  <p>
                    Ugx. 00.00 <br />
                  </p>

                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    {/* ${activeIndex + 1} / ${totalItems} */}
                    <CircularProgressbar
                      value={percentage1}
                      text={`25%`}
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative">
                <img
                  src="images/catbanner-02.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage2}
                      text={`30%`}
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/catbanner-03.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now</h5>
                  <p>
                    Ugx. 00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage3}
                      text={`20%`}
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/catbanner-04.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5> Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage1}
                      text={`35%`}
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end wallet section for small screens */}

          <div className="col-6 remeoved-sm-screen">
            <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center">
              <div className="small-banner position-relative">
                <img
                  src="images/catbanner-01.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>

                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage1}
                      text={`${(((activeIndex + 1) / totalItems) * 100).toFixed(
                        0
                      )}%`} 
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative">
                <img
                  src="images/catbanner-02.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage2}
                      text={`${(((activeIndex + 1) / totalItems) * 100).toFixed(
                        0
                      )}%`} 
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/catbanner-03.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5>Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage3}
                      text={`${(((activeIndex + 1) / totalItems) * 100).toFixed(
                        0
                      )}%`} 
                      styles={buildStyles({
                        textSize: "2rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/catbanner-04.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h5> Buy Now</h5>
                  <p>
                    Ugx.00.00 <br />
                  </p>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      marginTop: "2px",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage1}
                      text={`${(((activeIndex + 1) / totalItems) * 100).toFixed(
                        0
                      )}%`} 
                      styles={buildStyles({
                        textSize: "1.8rem",
                        pathColor: "#4caf50",
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* End Of User Info.-- Only shows for Logged in users */}

      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-between">
              {services?.map((i, j) => {
                return (
                  <div className="d-flex align-items-center gap-15" key={j}>
                    <img src={i.image} alt="services" />
                    <div>
                      <h6>{i.title}</h6>
                      <p className="mb-0">{i.tagline}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>

      {/* for mobile only */}
      <Container class1="rm-on-lg-screen featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Amaja Shop</h3>
          </div>
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "featured") {
                return (
                  <div key={index} className={"col-9"}>
                    <div className="product-card position-relative">
                      <div className="wishlist-icon position-absolute">
                        <button className="border-0 bg-transparent">
                          <img
                            src={wish}
                            alt="wishlist"
                            onClick={(e) => {
                              addToWish(item?._id);
                            }}
                          />
                        </button>
                      </div>
                      <div className="product-image">
                        <img
                          src={item?.images[0]?.url}
                          //className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"260px"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                        <img
                          src={item?.images[0]?.url}
                          //className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"260px"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                      </div>
                      <div className="product-details">
                        <h6 className="brand">{item?.brand}</h6>
                        <h5 className="product-title">
                          {item?.title?.substr(0, 70) + "..."}
                        </h5>
                        <ReactStars
                          count={5}
                          size={24}
                          value={item?.totalrating.toString()}
                          edit={false}
                          activeColor="#ffd700"
                        />

                        <p className="price">Ugx. {item?.price}</p>
                      </div>
                      <div className="action-bar position-absolute">
                        <div className="d-flex flex-column gap-15"></div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Container>

      {/* end of mobile only */}

      {/* end of web only */}
      <Container class1="remeoved-sm-screen featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Amaja Shop</h3>
          </div>
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "featured") {
                return (
                  <div key={index} className={"col-3"}>
                    <div className="product-card position-relative">
                      <div className="wishlist-icon position-absolute">
                        <button className="border-0 bg-transparent">
                          <img
                            src={wish}
                            alt="wishlist"
                            onClick={(e) => {
                              addToWish(item?._id);
                            }}
                          />
                        </button>
                      </div>
                      <div className="product-image">
                        <img
                          src={item?.images[0]?.url}
                          //className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"260px"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                        <img
                          src={item?.images[0]?.url}
                          //className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"260px"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                      </div>
                      <div className="product-details">
                        <h6 className="brand">{item?.brand}</h6>
                        <h5 className="product-title">
                          {item?.title?.substr(0, 70) + "..."}
                        </h5>
                        <ReactStars
                          count={5}
                          size={24}
                          value={item?.totalrating.toString()}
                          edit={false}
                          activeColor="#ffd700"
                        />

                        <p className="price">Ugx. {item?.price}</p>
                      </div>
                      <div className="action-bar position-absolute">
                        <div className="d-flex flex-column gap-15">
                          {/* <button className="border-0 bg-transparent">
                            <img src={prodcompare} alt="compare" />
                          </button>
                          <button className="border-0 bg-transparent">
                            <img
                              onClick={() => navigate("/product/" + item?._id)}
                              src={view}
                              alt="view"
                            />
                          </button> */}
                          {/* <button className="border-0 bg-transparent">
                            <img src={addcart} alt="addcart" />
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Container>
      {/* end of web only */}

      <Container class1="special-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Special Products For You</h3>
          </div>
        </div>
        <div className="row">
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "special") {
                //console.log(item?._id);
                return (
                  <SpecialProduct
                    key={index}
                    id={item?._id}
                    title={item?.title}
                    brand={item?.brand}
                    totalrating={item?.totalrating.toString()}
                    price={item?.price}
                    img={item?.images[0].url}
                    sold={item?.sold}
                    quantity={item?.quantity}
                  />
                );
              }
            })}
        </div>
      </Container>
      <Container class1="popular-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Our Popular Products</h3>
          </div>
        </div>
        <div className="row">
          {productState &&
            productState?.map((item, index) => {
              if (item.tags === "popular") {
                return (
                  <div key={index} className={"col-9"}>
                    <div className="product-card position-relative">
                      <div className="wishlist-icon position-absolute">
                        <button className="border-0 bg-transparent">
                          <img
                            src={wish}
                            alt="wishlist"
                            onClick={(e) => {
                              addToWish(item?._id);
                            }}
                          />
                        </button>
                      </div>
                      <div className="product-image">
                        <img
                          src={item?.images[0].url}
                          // className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"100%"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                        <img
                          src={item?.images[0].url}
                          // className="img-fluid d"
                          alt="product image"
                          height={"250px"}
                          width={"100%"}
                          onClick={() => navigate("/product/" + item?._id)}
                        />
                      </div>
                      <div className="product-details">
                        <h6 className="brand">{item?.brand}</h6>
                        <h5 className="product-title">
                          {item?.title?.substr(0, 70) + "..."}
                        </h5>
                        <ReactStars
                          count={5}
                          size={24}
                          value={item?.totalrating.toString()}
                          edit={false}
                          activeColor="#ffd700"
                        />

                        <p className="price">Ugx. {item?.price}</p>
                      </div>
                      <div className="action-bar position-absolute">
                        <div className="d-flex flex-column gap-15">
                          {/* <button className="border-0 bg-transparent">
                            <img src={prodcompare} alt="compare" />
                          </button> */}
                          {/* <button className="border-0 bg-transparent">
                            <img
                              onClick={() => navigate("/product/" + item?._id)}
                              src={view}
                              alt="view"
                            />
                          </button> */}
                          {/* <button className="border-0 bg-transparent">
                            <img src={addcart} alt="addcart" />
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Container>

      <Container class1="marque-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="marquee-inner-wrapper card-wrapper">
              <Marquee className="d-flex">
                <div className="mx-4 w-25">
                  <img src="images/brand-01.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-02.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-03.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-04.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-05.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-06.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-07.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/brand-08.png" alt="brand" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
