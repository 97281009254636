import React, { useEffect, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { BsSearch, BsX } from "react-icons/bs";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getAProduct } from "../features/products/productSlilce";
import { getUserCart } from "../features/user/userSlice";

const HamburgerMenu = ({ isOpen, toggleHamburger }) => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state?.auth?.cartProducts);
  const authState = useSelector((state) => state?.auth);
  const [total, setTotal] = useState(null);
  const [paginate, setPaginate] = useState(true);
  const productState = useSelector((state) => state?.product?.product);
  const navigate = useNavigate();

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, []);

  const [productOpt, setProductOpt] = useState([]);
  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState[index].quantity) * cartState[index].price;
      setTotal(sum);
    }
  }, [cartState]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?._id, name: element?.title });
    }
    setProductOpt(data);
  }, [productState]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="hamburger-menu">
      <div className="burger-wrapper" onClick={toggleHamburger}>
        {isOpen ? (
          <BsX className="close-icon" />
        ) : (
          <>
            <div className="burger burger1" />
            <div className="burger burger2" />
            <div className="burger burger3" />
          </>
        )}
      </div>
      {isOpen && (
        <div className="menu-links">
          <div className="search-bar">
            <Typeahead
              id="pagination-example"
              onPaginate={() => console.log("Results paginated")}
              onChange={(selected) => {
                navigate(`/product/${selected[0]?.prod}`);
                dispatch(getAProduct(selected[0]?.prod));
              }}
              options={productOpt}
              paginate={paginate}
              labelKey={"name"}
              placeholder="Search for Products here"
            />
            <span className="input-group-text p-3" id="basic-addon2">
              <BsSearch className="fs-6" />
            </span>
          </div>
          <div className="menu-item">
            <Link
              to="/wishlist"
              className="d-flex align-items-center gap-10 text-white"
              onClick={toggleHamburger}
            >
              <img src={wishlist} alt="wishlist" />
              <p className="mb-0">
                Favourite <br /> wishlist
              </p>
            </Link>
          </div>
          <div className="menu-item">
            <Link
              to={authState?.user === null ? "/login" : "my-profile"}
              className="d-flex align-items-center gap-10 text-white"
              onClick={toggleHamburger}
            >
              <img src={user} alt="user" />
              {authState?.user === null ? (
                <p className="mb-0">
                  Log in <br /> My Account
                </p>
              ) : (
                <p className="mb-0">Welcome {authState?.user?.firstname}</p>
              )}
            </Link>
          </div>
          <div className="menu-item">
            <Link
              to="/cart"
              className="d-flex align-items-center gap-10 text-white"
              onClick={toggleHamburger}
            >
              <img src={cart} alt="cart" />
              <div className="d-flex flex-column gap-10">
                <span className="badge bg-white text-dark">
                  {cartState?.length ? cartState?.length : 0}
                </span>
                <p className="mb-0">
                  Ugx. {!cartState?.length ? 0 : total ? total : 0}
                </p>
              </div>
            </Link>
          </div>
          <div className="nav-links">
            <div className="d-flex align-items-center gap-15 flex-wrap">
              <NavLink to="/" className="nav-link"  onClick={toggleHamburger} >Home</NavLink>
              <NavLink to="/product" className="nav-link"  onClick={toggleHamburger} >Our Store</NavLink>
              <NavLink to="/my-orders" className="nav-link"  onClick={toggleHamburger} >My Orders</NavLink>
              <NavLink to="/blogs" className="nav-link"  onClick={toggleHamburger} >Blogs</NavLink>
              <NavLink to="/contact" className="nav-link"  onClick={toggleHamburger} >Contact</NavLink>
              {authState?.user !== null ? (
                <button
                  className="border border-0 bg-transparent text-white text-uppercase"
                  type="button"
                  style={{ backgroundColor: "#232f3e" }}
                  onClick={handleLogout}
                >
                  LogOut
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .hamburger-menu {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: space-around;
          flex-flow: column nowrap;
          z-index: 10;
          cursor: pointer;
          border-radius: 50%;
        
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .burger {
          width: 2.5rem;
          height: 0.3rem;
          border-radius: 10px;
          background-color: white;
          transform-origin: 1px;
          transition: all 0.3s linear;
        }
        .burger1 {
          transform: ${isOpen ? "rotate(45deg)" : "rotate(0)"};
        }
        .burger2 {
          transform: ${isOpen ? "translateX(100%)" : "translateX(0)"};
          opacity: ${isOpen ? 0 : 1};
        }
        .burger3 {
          transform: ${isOpen ? "rotate(-45deg)" : "rotate(0)"};
        }
        .menu-links {
          display: none;
          position: absolute;
          top: 3.5rem;
          left: 0;
          width: 100%;
          background-color: #333;
          padding: 1.5rem;
          z-index: 9;
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        .menu-item {
          margin-bottom: 1.5rem;
        }
        .nav-links {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .nav-link {
          color: white;
          text-decoration: none;
          width: 48%;
          text-align: center;
          margin-bottom: 10px;
        }
        .close-icon {
          background-color: white;
          position: absolute;
          top: 1rem;
          left: 1rem;
          font-size: 2rem;
          font-weight: bold;
          cursor: pointer;
        }
        @media (max-width: 767px) {
          .menu-links {
            display: ${isOpen ? "block" : "none"};
          }
        }
        @media (min-width: 768px) {
          .hamburger-menu {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default HamburgerMenu;
