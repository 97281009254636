import React, { useEffect } from "react";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../features/user/userSlice";

const OrderReceipt = () => {
  const dispatch = useDispatch();
  const orderState = useSelector(
    (state) => state?.auth?.getorderedProduct?.orders
  );

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getOrders(config2));
  }, [dispatch, config2]);

  return (
    <>
      <Container class1="receipt-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="receipt-header">
              <h2>Order Confirmation Receipt</h2>
              <p>Thank you for your purchase! Below are the details of your order.</p>
            </div>

            {orderState &&
              orderState.map((order, index) => (
                <div className="receipt-order mb-5" key={index}>
                  <div className="order-summary mb-4">
                    <h4>Order Summary</h4>
                    <p><strong>Order ID:</strong> {order._id}</p>
                    <p><strong>Total Amount:</strong> ${order.totalPrice}</p>
                    <p><strong>Status:</strong> {order.orderStatus}</p>
                    <p><strong>Order Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>
                  </div>

                  <div className="order-items">
                    <h4>Items in Your Order</h4>
                    <div className="row order-items-header">
                      <div className="col-3">
                        <h6>Product</h6>
                      </div>
                      <div className="col-3">
                        <h6>Quantity</h6>
                      </div>
                      <div className="col-3">
                        <h6>Price</h6>
                      </div>
                      <div className="col-3">
                        <h6>Color</h6>
                      </div>
                    </div>

                    {order.orderItems.map((item, index) => (
                      <div className="row order-item py-2" key={index}>
                        <div className="col-3">
                          <p>{item.product.title}</p>
                        </div>
                        <div className="col-3">
                          <p>{item.quantity}</p>
                        </div>
                        <div className="col-3">
                          <p>${item.price}</p>
                        </div>
                        <div className="col-3">
                          <div
                            className="color-box"
                            style={{ backgroundColor: item.color.title }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default OrderReceipt;
